import { BASE_URL } from "./base";

export function fetchOfficers(): Promise<OfficerPageComponent[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/officers`, {
            method: "GET",
            credentials: "include",
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((json) => {
                        if (json.success) {
                            resolve(json.data);
                        } else {
                            reject(json.message);
                        }
                    });
                } else {
                    reject(res.statusText);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function updateOfficers(officers: OfficerPageComponent[]): Promise<boolean> {
    console.log(officers);
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/officers`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: officers,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((json) => {
                        if (json.success) {
                            resolve(true);
                        } else {
                            reject(json.message);
                        }
                    });
                } else {
                    reject(res.statusText);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
