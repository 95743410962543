const CONFIG = {
    webmaster: {
        email: "riverhawkoa@gmail.com",
    },
    newsletter: {
        url: "https://riverhawkoa.us15.list-manage.com/subscribe?u=2f759222bd009481c2dd3f4d6&id=1306c9e30d",
    },
    dues_url: "https://scoutingevent.com/220-2024LodgeDues",
    service_reporting_url: "https://forms.gle/5rWtsAJWQgEHPbaeA",
};

export default CONFIG;
