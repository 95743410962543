import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Input, Snackbar, Stack, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getShopConfig, getShopOrders } from "../../api/shop";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";
import { setKV } from "../../api/kv";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

const columns: GridColDef[] = [
    { field: "name", headerName: "Item Name", width: 200 },
    { field: "size", headerName: "Size", width: 130 },
    {
        field: "quantity",
        headerName: "Qty",
        type: "number",
        width: 90,
    },
];

const AdminShop = () => {
    const { setSiteState } = useContext(SiteStateContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const [config, setConfig] = useState<Shop_Config>();
    const [orders, setOrders] = useState<Shop_Order[]>();

    const [tab, setTab] = useState<number>(0);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const [settings_taxMultiplier, setSettings_taxMultiplier] = useState<number>(0);
    const [settings_closingDate, setSettings_closingDate] = useState<Date>(new Date());

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Shop - Admin",
                    navHighlight: "/admin/shop",
                    adminPage: true,
                    actions: [
                        {
                            text: "View Shop",
                            icon: ShoppingCartIcon,
                            onClick: () => {
                                navigate("/shop");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        getShopConfig()
            .then((cfg) => {
                setConfig(cfg);

                if (cfg) {
                    setSettings_taxMultiplier(cfg.tax_multiplier);
                    setSettings_closingDate(cfg.closing_date);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        getShopOrders()
            .then((orders) => {
                setOrders(orders);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [config]);

    interface Item {
        id: string;
        name: string;
        quantity: number;
        size: string;
    }

    function itemConvert(items: Shop_CartItem[], replacedId: boolean = false) {
        if (!config) return [];

        const converted: Item[] = [];

        items.forEach((itm) => {
            const existing = converted.find((v) => v.id === itm.id && v.size === itm.size);

            if (existing) {
                existing.quantity += 1;
            } else {
                converted.push({
                    id: itm.id,
                    name: config.items.find((i) => i.id === itm.id)?.name || "item not found",
                    quantity: 1,
                    size: itm.size,
                });
            }
        });

        if (replacedId) {
            converted.forEach((itm) => {
                itm.id = `${itm.name}-${itm.size}`;
            });
        }

        return converted;
    }

    function reduceEmails(emails: string[]) {
        const reduced: string[] = [];

        emails.forEach((email) => {
            if (!reduced.includes(email)) {
                reduced.push(email);
            }
        });

        return reduced;
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    return (
        <AdminLayout>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                message={snackbarMessage}
            />
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Orders
                </Typography>
                {!loading && (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tab}
                                onChange={(_, val) => {
                                    setTab(val);
                                }}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Orders" />
                                <Tab label="Consolidated" />
                                <Tab label="Emails" />
                                <Tab label="Settings" />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tab} index={0}>
                            {orders?.map((order) => (
                                <Accordion key={order.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography sx={{ width: "33%", flexShrink: 0 }}>{order.name}</Typography>
                                        <Typography sx={{ color: "text.secondary" }}>{order.email}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {itemConvert(order.items).map((item, idx) => (
                                            <Typography key={idx}>
                                                x{item.quantity} {item.name} - {item.size.toUpperCase()}
                                            </Typography>
                                        ))}
                                        <Typography sx={{ color: "text.secondary" }}>ID: {order.id}</Typography>
                                        <Typography sx={{ color: "text.secondary" }}>Created: {new Date(order.created).toISOString()}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <DataGrid rows={itemConvert(orders?.flatMap((o) => o.items) || [], true)} columns={columns} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={2}>
                            {reduceEmails(orders?.map((o) => o.email) || []).map((email, idx) => (
                                <Typography key={idx}>{email}</Typography>
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={3}>
                            {/* Enable/Disable store */}
                            <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                                Store Toggles
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const open = !config?.open;

                                        if (config) {
                                            setConfig({
                                                ...config,
                                                open,
                                            });
                                        }

                                        setKV("shop_open", open.toString()).then(() => {
                                            setSnackbarMessage(`Store is now ${open ? "open" : "closed"}`);
                                            setSnackbarOpen(true);
                                        });
                                    }}
                                >
                                    {config?.open ? "Close Store" : "Open Store"}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const analytics_enabled = !config?.analytics_enabled;

                                        if (config) {
                                            setConfig({
                                                ...config,
                                                analytics_enabled,
                                            });
                                        }

                                        setKV("shop_analytics_enabled", analytics_enabled.toString()).then(() => {
                                            setSnackbarMessage(`Analytics are now ${analytics_enabled ? "enabled" : "disabled"}`);
                                            setSnackbarOpen(true);
                                        });
                                    }}
                                >
                                    {config?.analytics_enabled ? "Disable Analytics" : "Enable Analytics"}
                                </Button>
                            </Stack>

                            {/* Tax multiplier */}
                            <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                                Tax Multiplier
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Input
                                    type="number"
                                    placeholder="Tax Multiplier"
                                    value={config?.tax_multiplier}
                                    onInput={(v) => {
                                        if (v.target instanceof HTMLInputElement) {
                                            const tax_multiplier = parseFloat(v.target.value);

                                            setSettings_taxMultiplier(tax_multiplier);
                                        }
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (config) {
                                            setConfig({
                                                ...config,
                                                tax_multiplier: settings_taxMultiplier,
                                            });
                                        }

                                        setKV("shop_tax_multiplier", settings_taxMultiplier.toString()).then(() => {
                                            setSnackbarMessage(`Tax multiplier set to ${settings_taxMultiplier}`);
                                            setSnackbarOpen(true);
                                        });
                                    }}
                                >
                                    Set Tax Multiplier
                                </Button>
                            </Stack>

                            {/* Closing date */}
                            <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                                Closing Date
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={dayjs(settings_closingDate)}
                                        onChange={(val) => {
                                            if (!val) return;
                                            console.log("123");
                                            // setSettings_closingDate(val.toDate());
                                        }}
                                        onAccept={(val) => {
                                            if (!val) return;
                                            setSettings_closingDate(val.toDate());
                                        }}
                                    />
                                </LocalizationProvider>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (config) {
                                            setConfig({
                                                ...config,
                                                closing_date: new Date(settings_closingDate),
                                            });
                                        }

                                        setKV("shop_closing_date", settings_closingDate.toISOString()).then(() => {
                                            setSnackbarMessage(`Closing date set to ${settings_closingDate.toISOString()}`);
                                            setSnackbarOpen(true);
                                        });
                                    }}
                                >
                                    Set Closing Date
                                </Button>
                            </Stack>

                            {/* Raw settings */}
                            <Accordion sx={{ mt: 2 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>Raw Settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <pre>
                                        <code>{JSON.stringify(config, null, 2)}</code>
                                    </pre>
                                </AccordionDetails>
                            </Accordion>
                        </CustomTabPanel>
                    </>
                )}
            </Container>
        </AdminLayout>
    );
};

export default AdminShop;
