import { useEffect, useState } from "react";
import { NotFoundPage } from "./NotFoundPage";
import { getPage } from "../api/page";
import MarkdownPage from "./MarkdownPage";
import { useLocation } from "react-router-dom";

const MarkdownOrNotFound = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [found, setFound] = useState<boolean>(false);
    const [page, setPage] = useState<Page>();
    const location = useLocation();

    useEffect(() => {
        getPage(location.pathname)
            .then((page) => {
                setPage(page);
                setFound(true);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [setFound, setPage, location]);

    return <>{!loading && <>{found && page && page.live ? <MarkdownPage title={page.title} navHighlight={page.navHighlight} markdown={page.content} /> : <NotFoundPage />}</>}</>;
};

export default MarkdownOrNotFound;
