import { useContext, useEffect, useState } from "react";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { sendFeedback } from "../api/feedback";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import gaEvents from "../util/gaEvent";

const FeedbackPage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Feedback",
                    navHighlight: "/feedback",
                    actions: [
                        {
                            icon: MailIcon,
                            text: "View Messages",
                            onClick: () => {
                                navigate("/admin/messages");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState]);

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Feedback</Typography>
                    <Typography variant="body1">Please use this form to send us feedback about our website, events, or anything else.</Typography>
                    {errorText && (
                        <Typography variant="body2" color="error">
                            *{errorText}
                        </Typography>
                    )}
                </Grid>
                {/* Name */}
                <Grid item xs={12} md={6}>
                    <TextField label="Name" variant="outlined" fullWidth value={name} required={true} onChange={(e) => setName(e.target.value)} />
                </Grid>
                {/* Email */}
                <Grid item xs={12} md={6}>
                    <TextField label="Email" variant="outlined" fullWidth value={email} required={true} onChange={(e) => setEmail(e.target.value)} />
                </Grid>
                {/* Subject */}
                <Grid item xs={12}>
                    <TextField label="Subject" variant="outlined" fullWidth value={subject} required={true} onChange={(e) => setSubject(e.target.value)} />
                </Grid>
                {/* Message */}
                <Grid item xs={12}>
                    <TextField label="Message" variant="outlined" fullWidth multiline rows={10} value={message} required={true} onChange={(e) => setMessage(e.target.value)} />
                </Grid>
                {/* Submit */}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            // Submit feedback
                            sendFeedback({ name, email, subject, message })
                                .then(() => {
                                    // Clear form
                                    setName("");
                                    setEmail("");
                                    setSubject("");
                                    setMessage("");
                                    setErrorText(null);
                                })
                                .catch((err) => {
                                    setErrorText(err);
                                });

                            gaEvents.feedback.submit_form();
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FeedbackPage;
