import { Container, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import AdminLayout from "./AdminLayout";
import { fixSiteState } from "../../util/site_state";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { getFeedbackById } from "../../api/feedback";
import MailIcon from "@mui/icons-material/Mail";
import dayjs from "dayjs";

const AdminViewMessage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const navigate = useNavigate();
    const params = useParams();

    const [feedback, setFeedback] = useState<FeedbackMessage | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Messages - Admin",
                    navHighlight: "/admin/messages",
                    adminPage: true,
                    actions: [
                        {
                            icon: MailIcon,
                            text: "All Messages",
                            onClick: () => {
                                navigate("/admin/messages");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState]);

    useEffect(() => {
        if (!params.id) return navigate("/admin/messages");
        getFeedbackById(params.id)
            .then((feedback) => {
                setFeedback(feedback);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    });

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    View Message
                </Typography>
                {loading ? (
                    <Typography variant="body1">Loading...</Typography>
                ) : (
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4">
                                    <strong>Subject: </strong>
                                    {feedback?.subject}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0}>
                                    <Typography variant="h6">
                                        <strong>Name: </strong> {feedback?.name}
                                    </Typography>
                                    <Typography variant="h6">
                                        <strong>Email: </strong> {feedback?.email}
                                    </Typography>
                                    <Typography variant="h6">
                                        <strong>Timestamp: </strong> {dayjs(feedback?.timestamp).format()}
                                    </Typography>
                                    <Typography variant="h6">
                                        <strong>IP Address: </strong> {feedback?.ip}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
                                <strong>Message:</strong>
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                {feedback?.message}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Container>
        </AdminLayout>
    );
};

export default AdminViewMessage;
