import { BASE_URL } from "./base";

export function authLogin(username: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auth/login`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: username,
                password: password,
            }),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<AuthLoginResponse>;
                    console.log(data);
                    if (!data.success) return reject("Failed to login");
                    if (!data.data.loggedIn) return reject(data.data.message);
                    resolve(data.data.loggedIn);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function authLogout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auth/logout`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<{}>;
                    if (!data.success) return reject("Failed to logout");
                    resolve(true);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function fetchAuthUser(): Promise<User> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auth/user`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<User>;
                    if (!data.success) return reject("Failed to get user");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
