import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PublicIcon from "@mui/icons-material/Public";
import { AppBar, Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, SwipeableDrawer, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, Link as RouterLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authLogout, fetchAuthUser } from "./api/auth";
import { fetchKV } from "./api/kv";
import { getLivePages } from "./api/page";
import { AuthContext } from "./contexts/AuthContext";
import { SiteStateContext } from "./contexts/SiteStateContext";
import gaEvents from "./util/gaEvent";
import stringToColor from "./util/string_color";

interface Page {
    title: string;
    link: string;
    navHighlight?: string;
    newtab?: boolean;
}

const pages: Page[] = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "Calendar",
        link: "/calendar",
    },
    {
        title: "Officers",
        link: "/officers",
    },
    {
        title: "Events",
        link: "/events",
    },
];

const footerLinks: Page[] = [
    {
        title: "Feedback",
        link: "/feedback",
    },
    {
        title: "River Hawk District",
        link: "https://baltimorebsa.org/riverhawk",
        newtab: true,
    },
    {
        title: "Nentico Lodge 12",
        link: "https://nentico.org",
        newtab: true,
    },
];

const Layout = () => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [show4R, setShow4R] = useState(false);
    const { siteState } = useContext(SiteStateContext);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [customPages, setCustomPages] = useState<Page[]>([]);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (queryParameters.get("ref")) {
            const referrer = queryParameters.get("ref")?.toLowerCase();
            window.history.pushState({}, "", document.location.href.split("?")[0]);
            if (!referrer) return;
            if (referrer === "4r") {
                setShow4R(true);
            }
            gaEvents.routes.refer(referrer);
        }
    }, [queryParameters]);

    useEffect(() => {
        const iVal = setInterval(() => {
            if (authUser) {
                fetchAuthUser()
                    .then((user) => {
                        if (setAuthUser) setAuthUser(user);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, 1000 * 60);
        return () => clearInterval(iVal);
    }, [authUser]);

    useEffect(() => {
        getLivePages()
            .then((pages) => {
                pages = pages.filter((p) => p.navOrder !== -1);
                pages.sort((a, b) => a.navOrder - b.navOrder);
                setCustomPages(pages.map((page) => ({ title: page.title, link: page.slug, navHighlight: page.navHighlight })));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [setCustomPages]);

    useEffect(() => {
        if (location.pathname === "/maintenance") return;
        if (authUser) return;
        fetchKV("site_enabled").then((res) => {
            if (res === "false") {
                navigate("/maintenance");
            }
        });
    }, [authUser, location]);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    document.title = `${siteState.title} - River Hawk Chapter`;

    function stringAvatar(name: string) {
        const split = name.split(" ");
        let v = split[0][0];
        v += split[1] ? split[1][0] : split[0][1];
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: v,
        };
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            {/* 4R Referrer Dialog */}
            <Dialog
                fullScreen={fullScreen}
                open={show4R}
                onClose={() => {
                    setShow4R(false);
                }}
            >
                <DialogTitle>{"Four Rivers Chapter is now River Hawk Chapter"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        At the start of 2023, Four Rivers and The Capital have merged to create River Hawk. fourriverschapter.org now redirects to riverhawkoa.org.
                        <br />
                        <br />
                        If you followed a link to get here, please let the link owner know to update their link.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShow4R(false);
                        }}
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Admin Bar */}
            {authUser && (
                <AppBar position={"sticky"} color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar variant="dense">
                        <Stack sx={{ flexGrow: 1 }} spacing={1} direction={"row"}>
                            {/* Left-aligned content */}
                            {siteState.adminPage ? (
                                <Button
                                    startIcon={<PublicIcon />}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                    variant="outlined"
                                    size="small"
                                >
                                    Public
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<HomeIcon />}
                                    onClick={() => {
                                        navigate("/admin");
                                    }}
                                    variant="outlined"
                                    size="small"
                                >
                                    Admin
                                </Button>
                            )}
                            {siteState.actions.map((action) => (
                                <Button key={action.text} startIcon={<action.icon />} onClick={action.onClick} variant="outlined" size="small" disabled={action.disabled ?? false}>
                                    {action.text}
                                </Button>
                            ))}
                        </Stack>
                        <Box sx={{ flexGrow: 0 }}>
                            {/* Right-Aligned content */}
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar {...stringAvatar(authUser.name)} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate("/admin");
                                        handleCloseUserMenu();
                                    }}
                                >
                                    <ListItemIcon>
                                        <DashboardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Dashboard</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate("/admin/account");
                                        handleCloseUserMenu();
                                    }}
                                >
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Account</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        authLogout()
                                            .then(() => {
                                                if (setAuthUser) setAuthUser(null);
                                                gaEvents.admin.logout();
                                                navigate("/");
                                            })
                                            .catch(() => {
                                                console.log("Error logging out");
                                            });
                                        handleCloseUserMenu();
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            {/* Nav bar */}
            {!siteState.adminPage && (
                <AppBar position="sticky" color="secondary">
                    <Toolbar>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            <img src="/img/rhc/RHC-Logo.png" alt="river hawk logo" style={{ height: "50px" }} />
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component={RouterLink}
                            to="/"
                            sx={{
                                mr: 2,
                                display: { xs: "none", md: "flex" },
                                fontFamily: "MuseoSlab700",
                                fontWeight: 700,
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            River Hawk Chapter
                        </Typography>
                        <Box flexGrow={1} sx={{ display: { xs: "none", md: "flex" } }}></Box>

                        {/* Mobile Nav New */}
                        <Fragment>
                            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => {
                                    setMobileNavOpen(true);
                                }}
                                color="inherit"
                                sx={{ display: { md: "none" } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <SwipeableDrawer
                                anchor={"left"}
                                open={mobileNavOpen}
                                onClose={() => {
                                    setMobileNavOpen(false);
                                }}
                                onOpen={() => {
                                    setMobileNavOpen(true);
                                }}
                            >
                                <Box
                                    sx={{ width: 250, pt: siteState.adminPage ? 3 : 0 }}
                                    role="presentation"
                                    onClick={() => {
                                        setMobileNavOpen(false);
                                    }}
                                    onKeyDown={() => {
                                        setMobileNavOpen(false);
                                    }}
                                >
                                    <List>
                                        {[...pages, ...customPages].map((page, index) => (
                                            <ListItem
                                                key={index}
                                                disablePadding
                                                onClick={() => {
                                                    navigate(page.link);
                                                }}
                                            >
                                                <ListItemButton>
                                                    {/* <ListItemIcon><page.icon /></ListItemIcon> */}
                                                    <ListItemText primary={page.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </SwipeableDrawer>
                        </Fragment>

                        {/* Mobile Nav Old */}
                        {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                {[...pages, ...customPages].map((page) => (
                                    <Link to={page.link} key={page.link} underline="none" component={RouterLink}>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page.title}</Typography>
                                        </MenuItem>
                                    </Link>
                                ))}
                            </Menu>
                        </Box> */}

                        {/* Desktop nav */}
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            {[...pages, ...customPages].map((page) => {
                                const match = page.link === siteState.navHighlight || page.navHighlight === siteState.navHighlight;

                                return (
                                    <Link to={page.link} key={page.link} underline="none" component={RouterLink}>
                                        <Button key={page.title} sx={{ color: "white", display: "block" }} variant={match ? "outlined" : "text"}>
                                            {page.title}
                                        </Button>
                                    </Link>
                                );
                            })}
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            <div style={{ minHeight: "75vh" }} role="main">
                <Outlet />
            </div>

            {/* Footer */}
            {!siteState.hideFooter && (
                <>
                    <Box sx={{ backgroundColor: "#36454F", p: 2 }} role="footer">
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h5" sx={{ color: "white" }}>
                                            River Hawk
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "white" }}>
                                            The River Hawk Chapter is a member of Nentico Lodge 12, Baltimore Area Council #220.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h5" sx={{ color: "white" }}>
                                            Links
                                        </Typography>
                                        <List>
                                            {footerLinks.map((page) => (
                                                <ListItem key={page.title} disablePadding>
                                                    <Link to={page.link} underline="hover" component={RouterLink} target="_blank">
                                                        <ListItemText primary={page.title} sx={{ color: "#E6E7E8" }} />
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    {/* Copyright */}
                    <Box sx={{ backgroundColor: theme.palette.primary.main, p: 2 }}>
                        <Typography variant="body2" sx={{ color: "white", textAlign: "center" }}>
                            <span
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    navigate("/admin/login");
                                }}
                            >
                                &copy;
                            </span>{" "}
                            2018-{new Date().getFullYear()} River Hawk Chapter
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Layout;
