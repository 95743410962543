import EventIcon from "@mui/icons-material/Event";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Button, ButtonGroup, CircularProgress, Container, Grid, Input, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventById, sendMessageToRegistrants } from "../../../api/event";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const AdminEventMail = () => {
    const params = useParams();
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [event, setEvent] = useState<EventItem>();
    const navigate = useNavigate();

    const [subject, setSubject] = useState<string>("");
    const [body, setBody] = useState<string>("");

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Mail - Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: EventIcon,
                            text: "All Events",
                            onClick: () => {
                                navigate(`/admin/events`);
                            },
                        },
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            onClick: () => {
                                navigate(`/event/${event?.url}`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, event]);

    useEffect(() => {
        if (!params.id) return;
        getEventById(params.id)
            .then((ev) => {
                setEvent(ev);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [params]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                        Send Email to Event
                    </Typography>
                    {event ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <ButtonGroup variant="outlined" fullWidth orientation="vertical">
                                    <Button
                                        onClick={() => {
                                            navigate(`/admin/events/${event.id}/edit`);
                                        }}
                                    >
                                        Return
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Typography variant="h4">Send Email to {event.title}</Typography>
                                <Typography variant="body1">Send an email to all users who have registered for this event.</Typography>

                                {/* Subject input */}
                                <Input
                                    placeholder="Subject"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    value={subject}
                                    onChange={(ev) => {
                                        setSubject(ev.target.value);
                                    }}
                                />

                                {/* Body input */}
                                <Input
                                    placeholder="Body"
                                    fullWidth
                                    multiline
                                    rows={10}
                                    sx={{ mt: 2 }}
                                    inputProps={{
                                        style: {
                                            fontFamily: "Roboto Mono",
                                        },
                                    }}
                                    value={body}
                                    onChange={(ev) => {
                                        setBody(ev.target.value);
                                    }}
                                />

                                {/* Send button */}
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                    onClick={() => {
                                        sendMessageToRegistrants(event.id, body, subject).then(() => {
                                            alert("Message sent!");
                                            navigate(`/admin/events/${event.id}/edit`);
                                        }).catch((err) => {
                                            alert("Error sending message!");
                                            console.error(err);
                                        });
                                    }}
                                >
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <CircularProgress />
                    )}
                </LocalizationProvider>
            </Container>
        </AdminLayout>
    );
};

export default AdminEventMail;
