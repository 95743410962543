import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Alert, Button, ButtonGroup, CircularProgress, Container, Grid, Snackbar, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getShopConfig } from "../api/shop";
import ShopItemCard from "../components/ShopItemCard";
import { AuthContext } from "../contexts/AuthContext";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";

export enum Shop_ItemCut {
    MENS,
    LADIES,
    UNISEX,
}

export enum Shop_ItemSize {
    ONE_SIZE = "one-size",
    XS = "xs",
    S = "s",
    M = "m",
    L = "l",
    XL = "xl",
    XXL = "2xl",
    XXXL = "3xl",
    XXXXL = "4xl",
}

const dateFormat: string = "MMM DD";

const ShopListPage = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const { authUser } = useContext(AuthContext);
    const [config, setConfig] = useState<Shop_Config>();
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [adminOverride, setAdminOverride] = useState<boolean>(false);

    const [filter, setFilter] = useState<"all" | "mens" | "ladies">("all");
    const [items, setItems] = useState<Shop_Item[]>([]);

    const [cart, setCart] = useState<Shop_CartItem[]>(sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")!) : []);

    const [alert, setAlert] = useState<string | null>(null);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Shop",
                    navHighlight: "/shop",
                    actions: [
                        {
                            icon: SellIcon,
                            text: "View Orders",
                            onClick: () => {
                                navigate("/admin/shop");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        if (authUser && authUser.role !== "user") {
            setAdminOverride(true);
        }
    }, [authUser]);

    useEffect(() => {
        getShopConfig()
            .then((cfg) => {
                setConfig(cfg);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!config) {
            return;
        }

        if (filter === "all") {
            setItems(config.items);
        } else if (filter === "mens") {
            setItems(config.items.filter((itm) => itm.cut === Shop_ItemCut.MENS));
        } else if (filter === "ladies") {
            setItems(config.items.filter((itm) => itm.cut === Shop_ItemCut.LADIES));
        }
    }, [config, filter, setItems]);

    useEffect(() => {
        sessionStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    function ShopDisplay(config: Shop_Config) {
        if (!config.items) {
            return <Typography variant="h5">No items are currently available.</Typography>;
        }

        return (
            <>
                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    {items.map((itm) => (
                        <Grid item xs={12} md={6} lg={4} key={itm.id}>
                            <ShopItemCard item={itm} />
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    }

    return (
        <Container>
            {/* Header */}
            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item xs={12} md={6}>
                    {/* Title */}
                    <Typography variant="h2">Shop</Typography>

                    {/* Shop info */}
                    {config && <Typography variant="h5">The shop will close on {dayjs(config.closing_date).format(dateFormat)}</Typography>}
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                    <Stack>
                        {/* Cart */}
                        <Button
                            variant="outlined"
                            startIcon={<ShoppingCartIcon />}
                            sx={{ marginLeft: "auto", mb: 3 }}
                            onClick={() => {
                                navigate("/shop/cart");
                            }}
                        >
                            Cart ({cart.length})
                        </Button>

                        {/* Filters */}
                        <ButtonGroup variant="text" sx={{ marginLeft: "auto" }}>
                            <Button onClick={() => setFilter("all")} variant={filter === "all" ? "contained" : "text"}>
                                All
                            </Button>
                            <Button onClick={() => setFilter("mens")} variant={filter === "mens" ? "contained" : "text"}>
                                Mens
                            </Button>
                            <Button onClick={() => setFilter("ladies")} variant={filter === "ladies" ? "contained" : "text"}>
                                Ladies
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Grid>
            </Grid>

            {/* Item list */}
            {loading || !config ? (
                <CircularProgress />
            ) : (
                <>
                    {!config.open && adminOverride && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            The shop is closed — because you are an admin, you still have access.
                        </Alert>
                    )}
                    {/* Only display shop when it's open */}
                    {config.open || adminOverride ? ShopDisplay(config) : <Typography variant="h5">The shop is currently closed.</Typography>}
                </>
            )}

            <Snackbar
                open={alert != null}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert(null);
                }}
                message={alert}
            />
        </Container>
    );
};

export default ShopListPage;
