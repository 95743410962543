import { BASE_URL } from "./base";

export function getHistoryCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/history/count`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get history count");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
