import { useContext, useEffect } from "react";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";
import { Container, Typography } from "@mui/material";

const MaintenancePage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Maintenance", navHighlight: "/Maintenance", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <>
            <Container>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Maintenance
                </Typography>
                <Typography variant="body1">This site is currently disabled. Please check back soon.</Typography>
            </Container>
        </>
    );
};

export default MaintenancePage;
