import { BASE_URL } from "./base";

// Upload new document to API
// export function uploadDocument(name: string, data: ArrayBuffer): Promise<DocumentItem> {
//     return new Promise((resolve, reject) => {
//         fetch(`${BASE_URL}/document`, {
//             method: "POST",
//             credentials: "include",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ name, data: Buffer.from(data) }),
//         })
//             .then(async (res) => {
//                 if (res.ok) {
//                     const data = (await res.json()) as BaseApiResponse<DocumentItem>;
//                     if (!data.success) return reject("Failed to upload document");
//                     resolve(data.data);
//                 } else {
//                     reject("Web<>API Error");
//                 }
//             })
//             .catch((err) => {
//                 console.error(err);
//                 reject("No connection to API");
//             });
//     });
// }

export function getPreSignedUrl(name: string, type: string): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/document`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, type }),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<string>;
                    if (!data.success) return reject("Failed to get pre-signed url");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

// Get document from API
export function getDocumentById(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/document/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<string>;
                    if (!data.success) return reject("Failed to get document");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

// List documents
export function listDocuments(): Promise<DocumentItem[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/document`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<{ Contents: DocumentItem[] }>;
                    if (!data.success) return reject("Failed to list documents");
                    resolve(data.data.Contents);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

// Delete document
export function deleteDocumentById(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/document/${id}`, {
            method: "DELETE",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject("Failed to delete document");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
