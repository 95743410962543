import { LoadingButton } from "@mui/lab";
import { Container, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authLogin, fetchAuthUser } from "../../api/auth";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { AuthContext } from "../../contexts/AuthContext";
import { fixSiteState } from "../../util/site_state";
import gaEvents from "../../util/gaEvent";

const AdminLoginPage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const [errMessage, setErrMessage] = useState<string | null>(null);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Login - Admin", navHighlight: "/admin", adminPage: false, hideFooter: true }));
        }
        if (authUser) {
            setLoading(true);
            setTimeout(() => {
                const to = queryParameters.get("to");
                if (to) {
                    navigate(to);
                } else {
                    navigate("/admin");
                }
            }, 2000);
        }
    }, [authUser, setSiteState, navigate]);

    function login() {
        setLoading(true);
        authLogin(email, password)
            .then(() => {
                fetchAuthUser().then((user) => {
                    if (setAuthUser) setAuthUser(user);
                    setLoading(false);
                    gaEvents.admin.login();
                    if (user.reset_password) {
                        console.log("Need to reset password");
                        navigate("/admin/reset-password");
                    } else {
                        const to = queryParameters.get("to");
                        if (to) {
                            console.log("going to", to);
                            navigate(to);
                        } else {
                            console.log("Going home");
                            navigate("/");
                        }
                    }
                });
            })
            .catch((err) => {
                console.log(err);
                setErrMessage(err);
                setLoading(false);
            });
    }

    return (
        <>
            <Container>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Login
                </Typography>
                <Stack spacing={3}>
                    {errMessage && <Typography variant="body1">{errMessage}</Typography>}
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        value={email}
                        onInput={(val) => {
                            // @ts-expect-error Value is from input element
                            setEmail(val.target.value);
                        }}
                        disabled={loading}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onInput={(val) => {
                            // @ts-expect-error Value is from input element
                            setPassword(val.target.value);
                        }}
                        onKeyUp={(ev) => {
                            if (ev.key === "Enter") {
                                login();
                            }
                        }}
                        disabled={loading}
                    />
                    <LoadingButton
                        variant="contained"
                        onClick={() => {
                            login();
                        }}
                        loading={loading}
                    >
                        Login
                    </LoadingButton>
                </Stack>
            </Container>
        </>
    );
};

export default AdminLoginPage;
