import { BASE_URL } from "./base";

export function getNextRoundtable(): Promise<RoundTableDate> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/roundtable/next`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<RoundTableDate>;
                    if (!data.success) return reject("Failed to get next roundtable");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                reject("No connection to API");
            });
    });
}

export function getRoundtableList(): Promise<RoundTableDate[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/roundtable/list`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<RoundTableDate[]>;
                    if (!data.success) return reject("Failed to get roundtable list");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                reject("No connection to API");
            });
    });
}

export function newRoundtable(): Promise<RoundTableDate> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/roundtable/new`, {
            method: "POST",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<RoundTableDate>;
                    if (!data.success) return reject("Failed to create new roundtable");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                reject("No connection to API");
            });
    });
}

export function deleteRoundtable(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/roundtable/${id}`, {
            method: "DELETE",
            credentials: "include",
        })
            .then((res) => {
                if (res.ok) {
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                reject("No connection to API");
            });
    });
}

export function putRoundtable(id: string, date: Date): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/roundtable/${id}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                dateTime: date,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                reject("No connection to API");
            });
    });
}
