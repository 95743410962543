import { useContext, useEffect } from "react";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";

const CalendarPage = () => {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Calendar", navHighlight: "/calendar" }));
        }
    }, [setSiteState]);

    return (
        <>
            <iframe title="calendar" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showCalendars=1&title=River%20Hawk%20Chapter%20Calendar&src=cml2ZXJoYXdrb2FAZ21haWwuY29t&src=cmhvYWVsZWN0aW9uc0BnbWFpbC5jb20&color=%23D50000" style={{ borderWidth: 0 }} width="100%" height="1000px" frameBorder={0} scrolling="no"></iframe>
        </>
    );
};

export default CalendarPage;
