import { BASE_URL } from "./base";

export function sendTestEmail(email: string) {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/dev/mail-test`, {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.success) {
                    resolve(true);
                } else {
                    reject(json.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
