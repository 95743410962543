import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Button, ButtonGroup, Container, Typography } from "@mui/material";
import update from "immutability-helper";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { fetchOfficers, updateOfficers } from "../../api/officer";
import { Card } from "../../components/OfficerEditCard";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";

const AdminOfficers = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [officers, setOfficers] = useState<OfficerPageComponent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Officers - Admin",
                    navHighlight: "/admin/officers",
                    actions: [
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            onClick: () => {
                                navigate("/officers");
                            },
                        },
                    ],
                    adminPage: true,
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        fetchOfficers()
            .then((officers) => {
                setOfficers(officers);
                setLoading(false);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [setOfficers, setLoading]);

    const moveOfficer = useCallback((dragIndex: number, hoverIndex: number) => {
        setOfficers((prevOfficers) =>
            update(prevOfficers, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevOfficers[dragIndex]],
                ],
            })
        );
    }, []);

    const renderOfficer = useCallback((officer: OfficerPageComponent, index: number) => {
        return (
            <Card
                key={officer.id}
                index={index}
                id={officer.id}
                officer={officer}
                moveOfficer={moveOfficer}
                updateOfficer={(val) => {
                    setOfficers((prevOfficers) => {
                        const newOfficers = [...prevOfficers];
                        newOfficers[index] = val;
                        return newOfficers;
                    });
                }}
                deleteOfficer={() => {
                    setOfficers((prevOfficers) => {
                        const newOfficers = [...prevOfficers];
                        newOfficers.splice(index, 1);
                        return newOfficers;
                    });
                }}
            />
        );
    }, []);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Officers
                </Typography>
                <div style={{ display: "flex", margin: "1rem" }}>
                    <ButtonGroup disableElevation variant="outlined" sx={{ marginLeft: "auto" }}>
                        <Button
                            startIcon={<PersonAddIcon />}
                            onClick={() => {
                                const newOfficers = [...officers];
                                newOfficers.push({
                                    isLine: false,
                                    title: "New Title",
                                    id: v4(),
                                    name: "New Officer",
                                    email: "new@example.com",
                                    unit: {
                                        type: "none" as UnitTypeEnum,
                                        num: 0,
                                    },
                                    honor: "none" as HonorEnum,
                                });
                                setOfficers(newOfficers);
                            }}
                        >
                            Add Officer
                        </Button>
                        <Button
                            startIcon={<SafetyDividerIcon />}
                            onClick={() => {
                                const newOfficers = [...officers];
                                newOfficers.push({
                                    isLine: true,
                                    id: v4(),
                                });
                                setOfficers(newOfficers);
                            }}
                        >
                            Add Divider
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => {
                                updateOfficers(officers)
                                    .then(() => {
                                        console.log("Saved");
                                    })
                                    .catch((err) => {
                                        console.warn(err);
                                    });
                            }}
                        >
                            Save
                        </Button>
                    </ButtonGroup>
                </div>
                {!loading && <>{officers.map((officer, index) => renderOfficer(officer, index))}</>}
            </Container>
        </AdminLayout>
    );
};

export default AdminOfficers;
