import { Button, Divider, IconButton, MenuItem, Paper, Select, Stack } from "@mui/material";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { useEffect, useState } from "react";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import "./RichEditor.css";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import CharacterCount from "@tiptap/extension-character-count";
import * as Y from "yjs";
import { TiptapCollabProvider } from "@hocuspocus/provider";

const colors = ["#958DF1", "#F98181", "#FBBC88", "#FAF594", "#70CFF8", "#94FADB", "#B9F18D"];

interface Props {
    content: string;
    setContent: (content: string) => void;
    editable: boolean;
    id: string;
}

const RichEditor = ({ content, setContent, editable, id }: Props) => {
    const ydoc = new Y.Doc();
    const websocketProvider = new TiptapCollabProvider({
        appId: "6kpe0pmq",
        name: id,
        document: ydoc,
    });

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                history: false,
            }),
            Highlight,
            TaskList,
            TaskItem,
            CharacterCount,
            Typography,
            Collaboration.configure({
                document: ydoc,
            }),
            CollaborationCursor.configure({
                provider: websocketProvider,
            }),
        ],
        content,
    });

    const [textDisplayStyle, setTextDisplayStyle] = useState<string>("paragraph");
    const [textActionStyle, setTextActionStyle] = useState<string>();

    useEffect(() => {
        if (editor) {
            editor.setEditable(editable);
        }
    }, [editable]);

    useEffect(() => {
        if (editor) {
            if (textActionStyle === "heading1") {
                editor.chain().focus().toggleHeading({ level: 1 }).run();
            } else if (textActionStyle === "heading2") {
                editor.chain().focus().toggleHeading({ level: 2 }).run();
            } else if (textActionStyle === "heading3") {
                editor.chain().focus().toggleHeading({ level: 3 }).run();
            } else if (textActionStyle === "heading4") {
                editor.chain().focus().toggleHeading({ level: 4 }).run();
            } else if (textActionStyle === "heading5") {
                editor.chain().focus().toggleHeading({ level: 5 }).run();
            } else if (textActionStyle === "heading6") {
                editor.chain().focus().toggleHeading({ level: 6 }).run();
            } else if (textActionStyle === "paragraph") {
                editor.chain().focus().setParagraph().run();
            }
        }
    }, [textActionStyle]);

    useEffect(() => {
        setInterval(() => {
            if (editor) {
                if (editor.isActive("heading", { level: 1 })) {
                    setTextDisplayStyle("heading1");
                } else if (editor.isActive("heading", { level: 2 })) {
                    setTextDisplayStyle("heading2");
                } else if (editor.isActive("heading", { level: 3 })) {
                    setTextDisplayStyle("heading3");
                } else if (editor.isActive("heading", { level: 4 })) {
                    setTextDisplayStyle("heading4");
                } else if (editor.isActive("heading", { level: 5 })) {
                    setTextDisplayStyle("heading5");
                } else if (editor.isActive("heading", { level: 6 })) {
                    setTextDisplayStyle("heading6");
                } else if (editor.isActive("paragraph")) {
                    setTextDisplayStyle("paragraph");
                }

                setContent(editor.getHTML());
            }
        }, 100);
    }, [editor?.isActive]);

    return (
        <>
            {editor && (
                <Paper sx={{ p: 2 }}>
                    <Stack direction={"row"} spacing={1}>
                        <IconButton onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
                            <UndoIcon />
                        </IconButton>
                        <IconButton onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
                            <RedoIcon />
                        </IconButton>
                        <Divider orientation="vertical" flexItem />
                        <Select
                            label="Style"
                            value={textDisplayStyle}
                            variant="standard"
                            onChange={(ev) => {
                                setTextActionStyle(ev.target.value);
                            }}
                        >
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="heading1">Heading 1</MenuItem>
                            <MenuItem value="heading2">Heading 2</MenuItem>
                            <MenuItem value="heading3">Heading 3</MenuItem>
                            <MenuItem value="heading4">Heading 4</MenuItem>
                            <MenuItem value="heading5">Heading 5</MenuItem>
                            <MenuItem value="heading6">Heading 6</MenuItem>
                        </Select>
                        <IconButton onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()} color={editor.isActive("bold") ? "primary" : "default"}>
                            <FormatBoldIcon />
                        </IconButton>
                        <IconButton onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()} color={editor.isActive("italic") ? "primary" : "default"}>
                            <FormatItalicIcon />
                        </IconButton>
                        <IconButton onClick={() => editor.chain().focus().toggleStrike().run()} disabled={!editor.can().chain().focus().toggleStrike().run()} color={editor.isActive("strike") ? "primary" : "default"}>
                            <StrikethroughSIcon />
                        </IconButton>
                        <Divider orientation="vertical" flexItem />
                        <IconButton onClick={() => editor.chain().focus().toggleBulletList().run()} color={editor.isActive("bulletList") ? "primary" : "default"}>
                            <FormatListBulletedIcon />
                        </IconButton>
                        <IconButton onClick={() => editor.chain().focus().toggleOrderedList().run()} color={editor.isActive("orderedList") ? "primary" : "default"}>
                            <FormatListNumberedIcon />
                        </IconButton>
                        <IconButton onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                            <HorizontalRuleIcon />
                        </IconButton>
                        {/* <Button onClick={() => editor.chain().focus().toggleCode().run()} disabled={!editor.can().chain().focus().toggleCode().run()} className={editor.isActive("code") ? "is-active" : ""}>
                            code
                        </Button> */}
                        {/* <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>clear marks</Button> */}
                        {/* <Button onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</Button> */}

                        {/* <Button onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive("paragraph") ? "is-active" : ""}>
                            paragraph
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}>
                            h1
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}>
                            h2
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}>
                            h3
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}>
                            h4
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()} className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}>
                            h5
                        </Button>
                        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()} className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}>
                            h6
                        </Button> */}

                        {/* <Button onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive("codeBlock") ? "is-active" : ""}>
                            code block
                        </Button> */}
                        {/* <Button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive("blockquote") ? "is-active" : ""}>
                            blockquote
                        </Button> */}

                        {/* <Button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</Button> */}
                    </Stack>
                    <Divider />
                    <EditorContent editor={editor} style={{ padding: 3 }} />
                </Paper>
            )}
        </>
    );
};

export default RichEditor;
