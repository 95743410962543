import { Container, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllPages } from "../../api/page";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";

const AdminPages = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const navigate = useNavigate();
    const [pages, setPages] = useState<BasicPageData[]>([]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Pages - Admin", navHighlight: "/admin/pages", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        getAllPages()
            .then((pages) => {
                setPages(pages);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Pages
                </Typography>
                <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                    {pages.length} Pages
                </Typography>
                {/* List all pages */}
                {pages.map((page) => (
                    <ListItem key={page.id} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                navigate(`/admin/pages/${page.id}`);
                            }}
                        >
                            <ListItemText primary={page.title} secondary={`${page.slug} (${page.live ? "published" : "draft"})`}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </Container>
        </AdminLayout>
    );
};

export default AdminPages;
