import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOfficers } from "../api/officer";
import CONFIG from "../config";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";
import { fetchKV } from "../api/kv";

const OfficersPage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [officers, setOfficers] = useState<OfficerPageComponent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [officerContractUrl, setOfficerContractUrl] = useState<string>("");

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Officers",
                    navHighlight: "/officers",
                    actions: [
                        {
                            icon: ModeEditIcon,
                            text: "Edit officers",
                            onClick: () => {
                                navigate("/admin/officers");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        fetchOfficers()
            .then((officers) => {
                setOfficers(officers);
                setLoading(false);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [setOfficers, setLoading]);

    useEffect(() => {
        fetchKV("officer_contract_url").then((url) => {
            setOfficerContractUrl(url);
        });
    }, []);

    function unitToString(unit: Unit) {
        switch (unit.type) {
            case "none":
                return "";
            case "pack":
                return `Pack ${unit.num}`;
            case "troop":
                return `Troop ${unit.num}`;
            case "crew":
                return `Crew ${unit.num}`;
            case "ship":
                return `Ship ${unit.num}`;
        }
    }

    function honorToImg(honor: HonorEnum) {
        switch (honor) {
            case "none":
                return "";
            case "ordeal":
                return <img src="/img/sash/sash_ordeal.png" alt="Ordeal" style={{ maxHeight: "20px" }} />;
            case "brotherhood":
                return <img src="/img/sash/sash_brotherhood.png" alt="Brotherhood" style={{ maxHeight: "20px" }} />;
            case "vigil":
                return <img src="/img/sash/sash_vigil.png" alt="Vigil" style={{ maxHeight: "20px" }} />;
        }
    }

    return (
        <Container sx={{ mb: 4 }}>
            <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                Officers
            </Typography>
            {!loading && (
                <Stack>
                    <Grid container>
                        <Grid item xs={12} md={2}>
                            <strong>Title</strong>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <strong>Unit</strong>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <strong>E-Mail</strong>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <strong>Honor</strong>
                        </Grid>
                    </Grid>
                    <Divider />
                    {officers.map((officer) => {
                        if (officer.isLine) {
                            return <Divider key={officer.id} />;
                        } else {
                            return (
                                <Grid container key={officer.id} sx={{ padding: 1 }}>
                                    <Grid item xs={12} md={2}>
                                        {officer.title}
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        {unitToString(officer.unit)}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {officer.name}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {officer.email}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {honorToImg(officer.honor)}
                                    </Grid>
                                </Grid>
                            );
                        }
                    })}
                </Stack>
            )}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                    Officer Contract
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    Interested in becoming an officer? Check out the officer contract below
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    <a href={officerContractUrl} target="_blank" rel="noreferrer">
                        Officer Contract
                    </a>
                </Typography>
            </Box>
        </Container>
    );
};

export default OfficersPage;
