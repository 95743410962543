import { SvgIconTypeMap } from "@mui/material";
import { createContext, Dispatch, SetStateAction, useState } from "react";

interface PageAction {
    icon: any;
    text: string;
    disabled?: boolean;
    onClick: () => void;
}

export interface SiteState {
    title: string;
    navHighlight: string;
    actions: PageAction[];
    adminPage: boolean;
    hideFooter: boolean;
}

export interface SiteStateInput {
    title: string;
    navHighlight: string;
    actions?: PageAction[];
    adminPage?: boolean;
    hideFooter?: boolean;
}

interface ContextProps {
    siteState: SiteState;
    setSiteState?: Dispatch<SetStateAction<SiteState>>;
}

export const SiteStateContext = createContext<ContextProps>({ siteState: { title: "", navHighlight: "", actions: [], adminPage: false, hideFooter: true } });

const SiteStateContextProvider = ({ children }: { children: any }) => {
    const [siteState, setSiteState] = useState<SiteState>({ title: "", navHighlight: "", actions: [], adminPage: false, hideFooter: true });

    return <SiteStateContext.Provider value={{ siteState, setSiteState }}>{children}</SiteStateContext.Provider>;
};

export default SiteStateContextProvider;
