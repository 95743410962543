import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { fetchAuthUser } from "../api/auth";

interface ContextProps {
    authUser: User | null;
    setAuthUser?: Dispatch<SetStateAction<User | null>>;
    ready: boolean;
    setReady?: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<ContextProps>({ authUser: null, ready: false });

const AuthContextProvider = ({ children }: { children: any }) => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        fetchAuthUser()
            .then((user) => {
                setAuthUser(user);
            })
            .catch((err) => {
                console.warn(err);
            })
            .finally(() => {
                setReady(true);
            });
    }, [setAuthUser]);

    return <AuthContext.Provider value={{ authUser, setAuthUser, ready, setReady }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
