import EventIcon from "@mui/icons-material/Event";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Button, ButtonGroup, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventById, updateEventById } from "../../../api/event";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";
import { AuthContext } from "../../../contexts/AuthContext";

import RichEditor from "../../../components/RichEditor";

const AdminEventEdit = () => {
    const params = useParams();
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [event, setEvent] = useState<EventItem>();
    const navigate = useNavigate();
    const theme = useTheme();
    const { authUser } = useContext(AuthContext);

    // details
    const [title, setTitle] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [start, setStart] = useState<Date>(new Date());
    const [end, setEnd] = useState<Date>(new Date());
    const [autoClose, setAutoClose] = useState<boolean>(false);
    const [autoCloseDate, setAutoCloseDate] = useState<Date>(new Date());
    const [description, setDescription] = useState<string>("");
    const [host, setHost] = useState<string>("");
    const [link, setLink] = useState<string>("");

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [archiveConfirmOpen, setArchiveConfirmOpen] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Edit - Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: EventIcon,
                            text: "All Events",
                            onClick: () => {
                                navigate(`/admin/events`);
                            },
                        },
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            disabled: event?.archived,
                            onClick: () => {
                                navigate(`/event/${event?.url}`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, event]);

    useEffect(() => {
        if (!params.id) return;
        getEventById(params.id)
            .then((ev) => {
                setEvent(ev);
                setTitle(ev.title);
                setUrl(ev.url);
                setLocation(ev.location);
                setStart(ev.start);
                setEnd(ev.end);
                setAutoClose(ev.autoClose);
                setAutoCloseDate(ev.closeDate);
                setDescription(ev.description);
                setHost(ev.host);
                setLink(ev.link);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [params]);

    return (
        <AdminLayout>
            <Dialog fullScreen={fullScreen} open={archiveConfirmOpen}>
                <DialogTitle>Archive Event?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to archive this event? You will not be able to unarchive it.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setArchiveConfirmOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!event) return;
                            updateEventById(event.id, { archived: true, enabled: false }).then(() => {
                                setEvent({ ...event, archived: true, enabled: false });
                                setArchiveConfirmOpen(false);
                            });
                        }}
                    >
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                        Edit Event
                    </Typography>
                    {event ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <ButtonGroup variant="outlined" fullWidth orientation="vertical">
                                    {!event.external && (
                                        <Button
                                            onClick={() => {
                                                navigate(`/admin/events/${event.id}/config`);
                                            }}
                                        >
                                            Config
                                        </Button>
                                    )}
                                    {!event.archived &&
                                        !event.external &&
                                        (event.enabled ? (
                                            <Button
                                                onClick={() => {
                                                    updateEventById(event.id, { enabled: false }).then(() => {
                                                        setEvent({ ...event, enabled: false });
                                                    });
                                                }}
                                                disabled={event.archived}
                                            >
                                                Disable
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    updateEventById(event.id, { enabled: true }).then(() => {
                                                        setEvent({ ...event, enabled: true });
                                                    });
                                                }}
                                                disabled={event.archived}
                                            >
                                                Enable
                                            </Button>
                                        ))}
                                    {!event.external && (
                                        <Button
                                            onClick={() => {
                                                navigate(`/admin/events/${event.id}/view`);
                                            }}
                                        >
                                            View Registrants
                                        </Button>
                                    )}
                                    {!event.archived && !event.external && (
                                        <Button
                                            onClick={() => {
                                                navigate(`/admin/events/${event.id}/mail`);
                                            }}
                                        >
                                            Send Email
                                        </Button>
                                    )}
                                    {event.archived && authUser && authUser.role === "root" && (
                                        <Button
                                            onClick={() => {
                                                updateEventById(event.id, { archived: false }).then(() => {
                                                    setEvent({ ...event, archived: false });
                                                });
                                            }}
                                        >
                                            Unarchive
                                        </Button>
                                    )}
                                    {!event.archived && (
                                        <Button
                                            onClick={() => {
                                                setArchiveConfirmOpen(true);
                                            }}
                                        >
                                            Archive
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Grid>

                            <Grid container item xs={12} md={9} spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Details</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* URL */}
                                    <TextField
                                        label="URL"
                                        fullWidth
                                        variant="standard"
                                        value={url}
                                        onChange={(ev) => {
                                            setUrl(ev.target.value);
                                        }}
                                        disabled={event.archived}
                                        helperText="The URL should contain no spaces; words must be separated by dashes. All lower-case."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Title */}
                                    <TextField
                                        label="Title"
                                        fullWidth
                                        variant="standard"
                                        value={title}
                                        onChange={(ev) => {
                                            setTitle(ev.target.value);
                                        }}
                                        disabled={event.archived}
                                        helperText="The title does not have the same restrictions as the URL."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Host */}
                                    {event.external && (
                                        <TextField
                                            label="Host"
                                            fullWidth
                                            variant="standard"
                                            value={host}
                                            onChange={(ev) => {
                                                setHost(ev.target.value);
                                            }}
                                            disabled={event.archived}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Link */}
                                    {event.external && (
                                        <TextField
                                            label="Link"
                                            fullWidth
                                            variant="standard"
                                            value={link}
                                            onChange={(ev) => {
                                                setLink(ev.target.value);
                                            }}
                                            disabled={event.archived}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* start */}
                                    <DateTimePicker
                                        value={dayjs(start)}
                                        onChange={(val) => {
                                            if (!val) return;
                                            setStart(val.toDate());
                                        }}
                                        sx={{ width: "100%" }}
                                        disabled={event.archived}
                                        label="Start Date/Time"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* end */}
                                    <DateTimePicker
                                        value={dayjs(end)}
                                        onChange={(val) => {
                                            if (!val) return;
                                            setEnd(val.toDate());
                                        }}
                                        sx={{ width: "100%" }}
                                        disabled={event.archived}
                                        label="End Date/Time"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Location */}
                                    {!event.external && (
                                        <TextField
                                            label="Location"
                                            fullWidth
                                            variant="standard"
                                            value={location}
                                            onChange={(ev) => {
                                                setLocation(ev.target.value);
                                            }}
                                            disabled={event.archived}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Description */}
                                    {!event.external && (
                                        <RichEditor
                                            content={event.description}
                                            setContent={(html) => {
                                                setDescription(html);
                                            }}
                                            editable={!event.archived}
                                            id={event.id}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {/* AutoClose enabled */}
                                    <Stack direction={"row"}>
                                        <Switch
                                            checked={autoClose}
                                            onChange={(ev) => {
                                                setAutoClose(ev.target.checked);
                                            }}
                                            disabled={event.archived}
                                        />
                                        <Typography>AutoClose</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {/* AutoClose date */}
                                    <DateTimePicker
                                        value={dayjs(autoCloseDate)}
                                        onChange={(val) => {
                                            if (!val) return;
                                            setAutoCloseDate(val.toDate());
                                        }}
                                        sx={{ width: "100%" }}
                                        disabled={event.archived}
                                        label="AutoClose Date/Time"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={event.archived}
                                        onClick={() => {
                                            updateEventById(event.id, {
                                                title,
                                                url,
                                                location,
                                                start,
                                                end,
                                                autoClose,
                                                closeDate: autoCloseDate,
                                                description,
                                                host,
                                                link,
                                            })
                                                .then(() => {
                                                    console.log("Saved");
                                                })
                                                .catch((err) => {
                                                    console.warn(err);
                                                });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <CircularProgress />
                    )}
                </LocalizationProvider>
            </Container>
        </AdminLayout>
    );
};

export default AdminEventEdit;
