export const BASE_URL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost";

// export function getRoundtableDates(): Promise<RoundTableDates> {
//     return fetch(`${BASE_URL}/roundtable-dates`, { method: "GET", credentials: "include" })
//         .then((res) => res.json())
//         .then((data) => data);
// }

export function getOfficers(): Promise<OfficerPageComponent[]> {
    return fetch(`${BASE_URL}/officers`, { method: "GET", credentials: "include" })
        .then((res) => res.json())
        .then((data) => data);
}

export function getAvailablePages(): Promise<BasicPageData[]> {
    return fetch(`${BASE_URL}/pages`, { method: "GET", credentials: "include" })
        .then((res) => res.json())
        .then((data) => data);
}
