import { BASE_URL } from "./base";

export function getOpenEventsCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/open/count`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get open events");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getOpenEvents(): Promise<PublicEventItem[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/open`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<PublicEventItem[]>;
                    if (!data.success) return reject("Failed to get open events");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getEventBySlug(slug: string): Promise<PublicEventItem> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/slug/${slug}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<PublicEventItem>;
                    if (!data.success) return reject("Failed to get event");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function registerForEvent(eventId: string, registration: EventRegistrationPost): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/register/${eventId}`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ registration }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<boolean>;
                    if (!data.success) return reject(data.message);
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getCurrentEventsAdmin(): Promise<EventItem[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/current`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem[]>;
                    if (!data.success) return reject("Failed to get current events");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getExternalEventsAdmin(): Promise<EventItem[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/external`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem[]>;
                    if (!data.success) return reject("Failed to get external events");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getArchivedEventsAdmin(): Promise<EventItem[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/archived`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem[]>;
                    if (!data.success) return reject("Failed to get archived events");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getEventById(id: string): Promise<EventItem> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/event/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem>;
                    if (!data.success) return reject("Failed to get event");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function updateEventById(id: string, event: Partial<EventItem>): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/event/${id}`, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({ event }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem>;
                    if (!data.success) return reject("Failed to update event");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getRegistrationsById(id: string): Promise<EventRegistrationEntry[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/registrations/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventRegistrationEntry[]>;
                    if (!data.success) return reject("Failed to get registrations");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function createEvent(event: Partial<EventItem>): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/event`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ event }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<EventItem>;
                    if (!data.success) return reject("Failed to create event");
                    resolve(data.data.id);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function downloadExport(id: string) {
    window.open(`${BASE_URL}/events/export/${id}`, "_blank");
}

// Send a message to all registered users of an event
export function sendMessageToRegistrants(id: string, message: string, subject: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/events/message/${id}`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ message, subject }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject("Failed to send message");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("Failed to send message");
            });
    });
}
