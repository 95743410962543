import { Button, Container, Input, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";
import { sendTestEmail } from "../../../api/dev";
import CONFIG from "../../../config";
import { deleteOrderById, getOrderById } from "../../../api/shop";
import gaEvents from "../../../util/gaEvent";

const AdminDev = () => {
    const { setSiteState } = useContext(SiteStateContext);

    // States
    const [email, setEmail] = useState("");
    const [orderId, setOrderId] = useState("");

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Dev - Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Dev
                </Typography>

                {/* Email test */}
                <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                    Email test
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onInput={(v) => {
                            if (v.target instanceof HTMLInputElement) {
                                setEmail(v.target.value);
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            sendTestEmail(email);
                            setEmail("");
                        }}
                    >
                        Send
                    </Button>
                </Stack>

                {/* Delete order by id */}
                <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                    Delete Order
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Input
                        type="text"
                        placeholder="ID"
                        value={orderId}
                        onInput={(v) => {
                            if (v.target instanceof HTMLInputElement) {
                                setOrderId(v.target.value);
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            getOrderById(orderId).then((order) => {
                                if (!order) {
                                    alert("Order not found");
                                    return;
                                }

                                deleteOrderById(orderId);

                                // gaEvents.shop.refund({
                                //     currency: "USD",
                                //     transaction_id: orderId,
                                //     value: order.total,
                                // });
                            });

                            setOrderId("");
                        }}
                    >
                        Delete
                    </Button>
                </Stack>

                {/* Config vars */}
                <Typography variant="h4" sx={{ mt: 2, mb: 4 }}>
                    Config vars
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
                    <pre>{JSON.stringify(CONFIG, null, 2)}</pre>
                </Typography>
            </Container>
        </AdminLayout>
    );
};

export default AdminDev;
