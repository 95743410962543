import { Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import AdminLayout from "./AdminLayout";
import { fixSiteState } from "../../util/site_state";

const AdminActivity = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const { authUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Activity - Admin", navHighlight: "/admin/activity", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Activity
                </Typography>
                <Typography variant="body1">
                    Currently managed through google analytics
                </Typography>
            </Container>
        </AdminLayout>
    );
};

export default AdminActivity;
