import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
    item: Shop_Item;
}

const ShopItemCard = ({ item }: Props) => {
    const navigate = useNavigate();

    return (
        <Card sx={{ height: "100%" }}>
            <CardActionArea
                onClick={() => {
                    navigate(`/shop/${item.id}`);
                }}
            >
                <CardMedia sx={{ height: 450 }} image={item.image} title={item.description} component={"img"} />
                <CardContent>
                    <Typography variant="h4">{item.name}</Typography>
                    <Stack spacing={1}>
                        <Stack direction={"row"} spacing={1}>
                            <DescriptionIcon />
                            <Typography variant="body2">{item.description}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <AttachMoneyIcon />
                            <Typography variant="body2">${item.price}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions></CardActions>
            </CardActionArea>
        </Card>
    );
};

export default ShopItemCard;
