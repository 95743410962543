import { Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";
import { fetchKV, setKV } from "../../../api/kv";

const AdminSiteManager = () => {
    const { setSiteState } = useContext(SiteStateContext);

    // Opts
    const [siteEnabled, setSiteEnabled] = useState<boolean>(true);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Site Manager - Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        fetchKV("site_enabled").then((res) => {
            if (res) {
                setSiteEnabled(res === "true");
            }
        });
    });

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Site Manager
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        console.log('123')
                        setKV("site_enabled", (!siteEnabled).toString())
                            .then(() => {
                                setSiteEnabled(!siteEnabled);
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }}
                >
                    {siteEnabled ? "Disable" : "Enable"}
                </Button>
            </Container>
        </AdminLayout>
    );
};

export default AdminSiteManager;
