import { Container, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOpenEventsCount } from "../../api/event";
import { getUnreadFeedbackCount } from "../../api/feedback";
import { getHistoryCount } from "../../api/history";
import { getMailChimpSubscriberCount } from "../../api/mailchimp";
import { getLivePagesCount } from "../../api/page";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";
import { getPendingOrderCount } from "../../api/shop";

const AdminDashboard = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const [historyCount, setHistoryCount] = useState<number>(-1);
    const [pageCount, setPageCount] = useState<number>(-1);
    const [subscriberCount, setSubscriberCount] = useState<number>(-1);
    const [eventCount, setEventCount] = useState<number>(-1);
    const [messageCount, setMessageCount] = useState<number>(-1);
    const [pendingOrders, setPendingOrders] = useState<number>(-1);

    const [loading, setLoading] = useState<boolean>(true);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Dashboard - Admin", navHighlight: "/admin", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        (async () => {
            setHistoryCount(await getHistoryCount());
            setPageCount(await getLivePagesCount());
            setSubscriberCount(await getMailChimpSubscriberCount());
            setEventCount(await getOpenEventsCount());
            setMessageCount(await getUnreadFeedbackCount());
            setPendingOrders(await getPendingOrderCount());
            setLoading(false);
        })();
    }, [setHistoryCount, setPageCount, setSubscriberCount, setEventCount, setMessageCount]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Hi, {authUser?.name}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : historyCount}
                            </Typography>
                            <Typography variant="body1">Pages Loaded</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : subscriberCount}
                            </Typography>
                            <Typography variant="body1">MailChimp Subscribers</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : eventCount}
                            </Typography>
                            <Typography variant="body1">Open events</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : messageCount}
                            </Typography>
                            <Typography variant="body1">Unread Messages</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : pageCount}
                            </Typography>
                            <Typography variant="body1">Pages</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                {loading ? <Skeleton /> : pendingOrders}
                            </Typography>
                            <Typography variant="body1">Pending Orders</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </AdminLayout>
    );
};

export default AdminDashboard;
