import { Box, Button, Divider, Grid, Link, List, ListItemButton, ListItemText, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { fetchKV } from "../api/kv";
import { getNextRoundtable } from "../api/roundtable";
import CONFIG from "../config";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";

const HomePage = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const [roundtable, setRoundtable] = useState<Date>();
    const [serviceHours, setServiceHours] = useState<number>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Home", navHighlight: "/" }));
        }
    }, [setSiteState]);

    useEffect(() => {
        getNextRoundtable()
            .then((date) => {
                setRoundtable(date.dateTime);
            })
            .catch((err) => {
                console.warn(err);
            });

        fetchKV("serviceHours")
            .then((hours) => {
                setServiceHours(parseInt(hours));
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [setRoundtable]);

    return (
        <div>
            <div
                style={{
                    minWidth: "100%",
                    minHeight: "60vh",
                    backgroundImage: "linear-gradient(to bottom, rgba(3, 145, 241, 0.5), rgba(3, 145, 241, 0.1)), url('/img/rhc/RHC-2023Officers.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    filter: "blur(2px) grayscale(25%)",
                }}
            ></div>
            <div style={{ position: "absolute", top: "42%", left: "50%", transform: "translateX(-50%) translateY(-50%)", width: "100%" }}>
                <Typography variant="h1" align="center" sx={{ color: "white", userSelect: "none", mb: 2 }} fontSize={isMobile ? "3rem" : theme.typography.h1.fontSize}>
                    River Hawk Chapter
                </Typography>
                <Divider style={{ backgroundColor: "white", width: "50%", margin: "auto" }} />
                <Typography variant="h2" align="center" fontSize={"1.5rem"} sx={{ color: "white", mt: 2, userSelect: "none" }}>
                    Nentico Lodge 12{!isMobile && " | Order of the Arrow | Boy Scouts of America"}
                </Typography>
                <div style={{ textAlign: "center", marginTop: "4rem" }}>
                    <img src="/img/rhc/RHC-Logo.png" style={{ height: "150px", userSelect: "none" }} draggable="false" alt="River Hawk Chapter Logo" />
                </div>
            </div>
            <Box sx={{ backgroundColor: "#36454F", color: "#fff", padding: 5 }}>
                <Typography variant="h4" align="center">
                    Our next chapter meeting
                </Typography>
                <Typography variant="h3" align="center" sx={{ m: 3 }}>
                    {!roundtable ? <Skeleton /> : new Date(roundtable).toLocaleString("en-US", { weekday: "short", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}
                </Typography>
                <Typography variant="body1" align="center">
                    View our{" "}
                    <Link component={RouterLink} to="/calendar">
                        calendar
                    </Link>{" "}
                    for more information
                </Typography>
            </Box>
            <Box sx={{ padding: 5, backgroundColor: "#fff" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                        <Typography variant="h4" align="center">
                            Renew your lodge dues
                        </Typography>
                        <Button variant="contained" color="primary" href={CONFIG.dues_url} target="_blank" sx={{ mt: 2 }}>
                            Renew
                        </Button>
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <Divider orientation={isMobile ? "horizontal" : "vertical"} />
                        </Grid>
                    )}
                    <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                        <Typography variant="h4" align="center">
                            Schedule your unit election
                        </Typography>
                        <Button variant="outlined" color="primary" href="mailto:RHOAElections@gmail.com?subject=River%20Hawk%20Unit%20Election" target="_blank" sx={{ mt: 2 }}>
                            Schedule now
                        </Button>
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <Divider orientation={isMobile ? "horizontal" : "vertical"} />
                        </Grid>
                    )}
                    {serviceHours && (
                        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                            <Typography variant="h4" align="center">
                                {serviceHours}
                            </Typography>
                            <Typography variant="body1" align="center">
                                Service hours reported
                            </Typography>
                            <Button variant="outlined" color="primary" href="/service" sx={{ mt: 2 }}>
                                Report hours
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box sx={{ m: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                        <Paper sx={{ width: "100%", maxWidth: isMobile ? 520 / 2 : 520, p: 1, marginLeft: "auto", marginRight: "auto" }}>
                            <Typography variant="h4" align="center" sx={{ p: 2, textAlign: "left" }}>
                                Quick links
                            </Typography>
                            <List>
                                {/* Service Hour Reporting */}
                                <ListItemButton component="a" href="/service" target="_blank">
                                    <ListItemText>Service Hour Reporting</ListItemText>
                                </ListItemButton>
                                {/* Newsletter */}
                                <ListItemButton component="a" href="/news" target="_blank">
                                    <ListItemText>Read the latest newsletter</ListItemText>
                                </ListItemButton>
                                <ListItemButton component="a" href="/newsletter" target="_blank">
                                    <ListItemText>Subscribe to the newsletter</ListItemText>
                                </ListItemButton>
                                {/* Nentico Lodge */}
                                <ListItemButton component="a" href="https://nentico.org" target="_blank">
                                    <ListItemText>Nentico Lodge</ListItemText>
                                </ListItemButton>
                                {/* Out of council call-out */}
                                <ListItemButton component="a" href="https://nentico.org/documents" target="_blank">
                                    <ListItemText>Out of council call-out</ListItemText>
                                </ListItemButton>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                        <Paper sx={{ width: isMobile ? 520 / 2 : 520, p: 1, marginLeft: "auto", marginRight: "auto" }}>
                            <iframe title="Facebook Embed" src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Friverhawkoa&tabs=timeline&width=${isMobile ? 250 : 500}&height=499&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId`} width={isMobile ? 250 : 500} height={499} style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default HomePage;
