import EventIcon from "@mui/icons-material/Event";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Button, ButtonGroup, CircularProgress, Container, FormControlLabel, FormGroup, Grid, Stack, Switch, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventById, updateEventById } from "../../../api/event";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const AdminEventConfig = () => {
    const params = useParams();
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [event, setEvent] = useState<EventItem>();
    const navigate = useNavigate();

    const [in_name, set_in_name] = useState<boolean>(true);
    const [in_unit, set_in_unit] = useState<boolean>(true);
    const [in_notes, set_in_notes] = useState<boolean>(true);
    const [in_group, set_in_group] = useState<boolean>(true);
    const [in_phone, set_in_phone] = useState<boolean>(true);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Configure - Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: EventIcon,
                            text: "All Events",
                            onClick: () => {
                                navigate(`/admin/events`);
                            },
                        },
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            onClick: () => {
                                navigate(`/event/${event?.url}`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, event]);

    useEffect(() => {
        if (!params.id) return;
        getEventById(params.id)
            .then((ev) => {
                setEvent(ev);
                set_in_name(ev.input_name);
                set_in_unit(ev.input_unit);
                set_in_notes(ev.input_notes);
                set_in_group(ev.input_group);
                set_in_phone(ev.input_phone);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [params]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                        Configure Event
                    </Typography>
                    {event ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <ButtonGroup variant="outlined" fullWidth orientation="vertical">
                                    <Button
                                        onClick={() => {
                                            navigate(`/admin/events/${event.id}/edit`);
                                        }}
                                    >
                                        Return
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Stack direction={"column"}>
                                    <FormControlLabel
                                        control={<Switch />}
                                        label="Name"
                                        checked={in_name}
                                        onChange={() => {
                                            set_in_name(!in_name);
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Switch />}
                                        label="Unit"
                                        checked={in_unit}
                                        onChange={() => {
                                            set_in_unit(!in_unit);
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Switch />}
                                        label="Notes"
                                        checked={in_notes}
                                        onChange={() => {
                                            set_in_notes(!in_notes);
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Switch />}
                                        label="Group"
                                        checked={in_group}
                                        onChange={() => {
                                            set_in_group(!in_group);
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Switch />}
                                        label="Phone"
                                        checked={in_phone}
                                        onChange={() => {
                                            set_in_phone(!in_phone);
                                        }}
                                    />
                                </Stack>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ mt: 4 }}
                                    onClick={() => {
                                        updateEventById(event.id, {
                                            input_name: in_name,
                                            input_unit: in_unit,
                                            input_notes: in_notes,
                                            input_group: in_group,
                                            input_phone: in_phone,
                                        })
                                            .then(() => {
                                                console.log("Saved");
                                            })
                                            .catch((err) => {
                                                console.warn(err);
                                            });
                                    }}
                                    disabled={event.archived}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <CircularProgress />
                    )}
                </LocalizationProvider>
            </Container>
        </AdminLayout>
    );
};

export default AdminEventConfig;
