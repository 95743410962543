import ReactGA from "react-ga4";

const gaEvents = {
    admin: {
        login: () => ReactGA.event({ category: "admin", action: "login" }),
        logout: () => ReactGA.event({ category: "admin", action: "logout" }),
    },
    events: {
        view: (id: string) => ReactGA.event({ category: "events", action: `view_${id}` }),
        register: (id: string) => ReactGA.event({ category: "events", action: `reg_${id}` }),
    },
    routes: {
        refer: (referrer: string) => ReactGA.event({ category: "routes", action: `refer_${referrer}` }),
    },
    newsletter: {
        newsletter_redirect: () => ReactGA.event({ category: "newsletter", action: `newsletter_redirect` }),
        newsletter_open: () => ReactGA.event({ category: "newsletter", action: `newsletter_open` }),
    },
    feedback: {
        submit_form: () => ReactGA.event({ category: "feedback", action: `submit_form` }),
    },
    shop: {
        // https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
        view: (options: any) => ReactGA.event("view_item", options),

        // https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
        add_to_cart: (options: any) => ReactGA.event("add_to_cart", options),

        // https://developers.google.com/tag-platform/gtagjs/reference/events#remove_from_cart
        remove_from_cart: (options: any) => ReactGA.event("remove_from_cart", options),

        // https://developers.google.com/tag-platform/gtagjs/reference/events#purchase
        checkout: (options: any) => ReactGA.event("purchase", options),

        // https://developers.google.com/tag-platform/gtagjs/reference/events#refund
        refund: (options: any) => ReactGA.event("refund", options),
    },
    misc: {
        service_redirect: () => ReactGA.event({ category: "misc", action: `service_redirect` }),
    },
};

export default gaEvents;
