import EventIcon from "@mui/icons-material/Event";
import { Button, Container, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";
import dayjs from "dayjs";
import { createEvent } from "../../../api/event";

const AdminEventCreate = () => {
    const params = useParams();
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const navigate = useNavigate();

    // Event Details
    const [title, setTitle] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [start, setStart] = useState<Date>(new Date());
    const [end, setEnd] = useState<Date>(new Date());
    const [autoClose, setAutoClose] = useState<boolean>(false);
    const [autoCloseDate, setAutoCloseDate] = useState<Date>(new Date());
    const [externalEvent, setExternalEvent] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Create - Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: EventIcon,
                            text: "All Events",
                            onClick: () => {
                                navigate(`/admin/events`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                        Create Event
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {/* URL */}
                            <TextField
                                label="URL"
                                fullWidth
                                variant="standard"
                                value={url}
                                onChange={(ev) => {
                                    setUrl(ev.target.value);
                                }}
                                helperText="The URL should contain no spaces; words must be separated by dashes. All lower-case."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* Title */}
                            <TextField
                                label="Title"
                                fullWidth
                                variant="standard"
                                value={title}
                                onChange={(ev) => {
                                    setTitle(ev.target.value);
                                }}
                                helperText="The title does not have the same restrictions as the URL."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* start */}
                            <DateTimePicker
                                value={dayjs(start)}
                                onChange={(val) => {
                                    if (!val) return;
                                    setStart(val.toDate());
                                }}
                                sx={{ width: "100%" }}
                                label="Start Date/Time"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* end */}
                            <DateTimePicker
                                value={dayjs(end)}
                                onChange={(val) => {
                                    if (!val) return;
                                    setEnd(val.toDate());
                                }}
                                sx={{ width: "100%" }}
                                label="End Date/Time"
                            />
                        </Grid>
                        <Grid item md={12}>
                            {/* External Event */}
                            <Stack direction={"row"}>
                                <Switch
                                    checked={externalEvent}
                                    onChange={(ev) => {
                                        setExternalEvent(ev.target.checked);
                                    }}
                                />
                                <Typography>External Event</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {/* AutoClose enabled */}
                            <Stack direction={"row"}>
                                <Switch
                                    checked={autoClose}
                                    onChange={(ev) => {
                                        setAutoClose(ev.target.checked);
                                    }}
                                />
                                <Typography>AutoClose</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3} md={9}>
                            {/* AutoClose date */}
                            <DateTimePicker
                                value={dayjs(autoCloseDate)}
                                onChange={(val) => {
                                    if (!val) return;
                                    setAutoCloseDate(val.toDate());
                                }}
                                sx={{ width: "100%" }}
                                disabled={!autoClose}
                                label="AutoClose Date/Time"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    createEvent({
                                        url: url.toLowerCase().replace(/ /g, "-"),
                                        title,
                                        start,
                                        end,
                                        location,
                                        autoClose,
                                        closeDate: autoCloseDate,
                                        description: "",
                                        external: externalEvent,
                                    })
                                        .then((id) => {
                                            navigate(`/admin/events/${id}/edit`);
                                        })
                                        .catch((err) => {
                                            console.warn(err);
                                        });
                                }}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Container>
        </AdminLayout>
    );
};

export default AdminEventCreate;
